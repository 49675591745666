<template>
  <div class="py-8 container">
    <!-- 操作 -->
    <div class="box flex md:flex-col md:space-y-4">
      <BackBtn
        :class="'md:self-start'"
        :title="'回收藏試卷列表'"
        :redirect-uri="$route.query.redirectUri"
      />
      <div class="flex ml-auto md:flex-col-reverse md:items-end">
        <Exclude
          v-if="$route.query?._exItemHide != 1"
          v-model="form.exItem"
          class="mr-6"
        />
        <BtnStart
          :total="questions.rowsData.total"
          :disabled="!rows.length"
          :folder-id="$route.query.folderId"
          :filter-id="$route.query.filterId"
          :type="$route.params.slug"
          :need-pick-order="true"
          :need-confirm="true"
        />
      </div>
    </div>

    <!-- 篩選 -->
    <div class="flex my-6 items-center">
      <Titles :text="'試題列表'" />
      <DataType
        v-model:dataType="form.dataType"
        v-model:dataTypeVal="form.dataTypeVal"
        :opts="questions.dataType"
        @update:dataType="(e) => (form.pg = 1)"
      />
    </div>

    <!-- 列表 -->
    <div class="my-6">
      <List :columns="['name', 'ansStatus', 'like', 'attribute', 'conf']" />
    </div>
    <TopBtn></TopBtn>
  </div>
</template>

<script>
import { computed, provide } from 'vue';

// @component
import DataType from '@/components/filter/DataTypeVal.vue';
import Titles from '@/components/Titles.vue';
import Exclude from '@/components/filter/Exclude.vue';

// @modules tester
import BtnStart from '@/modules/tester/BtnStart';
import BackBtn from '@/components/BackBtn.vue';
//@modules question
import List from '@/modules/question/List.vue';
import useQuestions from '@/modules/question/useQuestions';
import { useRoute } from 'vue-router';
import Question from '@/api/Question';

import TopBtn from '@/components/TopBtn.vue';
import usePgReset from '@/hook/usePgReset.js';
export default {
  components: {
    List,
    DataType,
    Exclude,
    BtnStart,
    Titles,
    TopBtn,
    BackBtn,
  },
  beforeRouteEnter(to, from, next) {
    usePgReset(to, from, next);
  },
  setup() {
    const route = useRoute();

    const fetchList = computed(() => {
      return Question.list;
    });

    const questions = useQuestions({
      method: fetchList,
      defaultForm: {
        type: route.params.slug,
        folderId: null,
        filterId: null,
        dataType: 'all',
        dataTypeVal: 'all',
        exItem: 'none',
      },
    });
    questions.fetcher();
    provide('questions', questions);

    return {
      form: questions.form,
      // EXAM_TYPE_SLUG,
      rows: questions.rowsData.rows,
      questions,
    };
  },
};
</script>
