import { ref, watch } from 'vue';
import useRows from '@/hook/useRows';
import useSaved from '@/modules/saved/useSaved';
import useConf from '@/modules/quest/conf/useConf.js';
import _ from 'lodash';
import { onBeforeRouteLeave, useRouter, useRoute } from 'vue-router';
export default ({ method, defaultForm }) => {
  const { confs, updateConfs, setConfs } = useConf();
  const {
    form: saveds,
    update: updateSaved,
    set: setSaveds,
  } = useSaved({ api: 'question' });

  const route = useRoute();
  const router = useRouter();
  const questions = useRows({
    defaultForm,
    method: method,
    cb: (res) => {
      if (res.code === 1 && res.data.rows) {
        if (res.data.type) {
          router.push({ query: { ...route.query, type: res.data.type } });
        }
        setConfs(res.data.rows);
        setSaveds(res.data.rows);
      }
    },
  });

  const updateForm = (params) => {
    if ('order' in params) {
      params.order = '' + params.order;
    }
    Object.assign(questions.form, { ...params });
  };

  const leaving = ref(false);
  watch(
    () => _.cloneDeep(questions.form),
    (val, old) => {
      if (leaving.value) return;

      if (_.isEqual(val, old)) return;
      questions.fetcher();
      questions.syncQuery();
    },
    { immediate: false, deep: true },
  );

  onBeforeRouteLeave((to, from) => {
    leaving.value = true;
  });
  return { ...questions, updateForm, saveds, updateSaved, confs, updateConfs };
};
