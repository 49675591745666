<template>
  <el-checkbox-group v-model="model">
    <El-Checkbox v-if="fields.indexOf('done') > -1" label="done">
      排除已做過題目
    </El-Checkbox>
    <El-checkbox v-if="fields.indexOf('ansCorrect') > -1" label="ansCorrect"
      >排除已作對題目</El-checkbox
    >
    <El-checkbox v-if="fields.indexOf('today') > -1" label="today"
      >排除今日做過的試題</El-checkbox
    >
  </el-checkbox-group>
</template>

<script>
import { computed, toRefs } from 'vue';
import { ElCheckbox, ElCheckboxGroup } from 'element-plus';

export default {
  components: {
    ElCheckboxGroup,
    ElCheckbox,
  },
  props: {
    fields: {
      type: Array,
      default: () => ['done', 'ansCorrect'],
    },
    modelValue: { type: String, default: '' },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const model = computed({
      get() {
        return !modelValue.value || modelValue.value === 'none'
          ? []
          : modelValue.value.split(',');
      },
      set(val) {
        console.log(val);
        const _val = val.length > 0 ? val.join() : 'none';
        emit('update:modelValue', _val);
      },
    });

    return { model };
  },
};
</script>
