<template>
  <El-Button @click="back">
    <font-awesome-icon :icon="['fas', 'reply']" class="mr-1" />
    {{ title }}</El-Button
  >
</template>
<script>
import { inject } from 'vue';
import { cloneDeep } from 'lodash';
import { useRouter } from 'vue-router';
import { uri2Route } from '@/utils/index.js';

export default {
  props: {
    title: {
      type: String,
      default: '返回',
    },
    redirectUri: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { state } = inject('store');
    const router = useRouter();
    const back = () => {
      let _route;
      if (props.redirectUri) {
        _route = uri2Route(decodeURIComponent(props.redirectUri));
      } else if (state.prev && state.prev.name) {
        const prev = cloneDeep(state.prev);
        _route = { ...prev };
      } else {
        router.back();
        return;
      }
      router.push({ ..._route });
    };
    return { back };
  },
};
</script>
