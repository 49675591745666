<template>
  <div class="flex space-x-4">
    <a
      v-for="opt in opts"
      :key="opt.id"
      href="#"
      class="text-sm py-2 border-primary"
      :class="
        form.dataTypeVal == opt.id
          ? 'text-primary border-b-2 font-bold'
          : 'text-gray  hover:opacity-75'
      "
      @click.prevent="toggle(opt)"
      >{{ opt.name }} ({{ opt.cnt }})
    </a>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import useWatch from '@/hook/useWatch';
export default {
  props: {
    opts: { type: Array, default: () => [] },

    dataType: {
      type: String,
      default: '',
    },
    dataTypeVal: {
      type: String,
      default: '',
    },
  },
  emits: ['update:dataType', 'update:dataTypeVal'],
  setup(props, { emit }) {
    const { opts, dataType, dataTypeVal } = toRefs(props);
    const form = reactive({
      dataType: '',
      dataTypeVal: '',
    });

    useWatch([dataType, dataTypeVal], form, { immediate: true });

    const onUpdated = () => {
      emit('update:dataType', form.dataType);
      emit('update:dataTypeVal', form.dataTypeVal);
    };

    const toggle = (opt) => {
      opts.value.forEach((x) => {
        if (x.id === opt.id) {
          opt.active = true;
          form.dataType = opt.prop;
          form.dataTypeVal = opt.id;
        } else {
          opt.active = false;
        }
      });
      onUpdated();
    };
    return { form, toggle };
  },
};
</script>
